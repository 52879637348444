import { useRef, useState } from 'react';
import { Carousel, Tabs } from 'antd';
import { Collapse } from 'antd';
import { ConfigProvider } from "antd";
import { Input } from 'antd';
import ContactModal from "./contactModal";

import emailjs from '@emailjs/browser';
import Footer from './Footer';

const { TextArea } = Input;

const Faq = () => {
    const ref = useRef();

      const general = [{
        key: '1',
        label: "What is a hackathon? Would I actually be hacking?",
        children: <p>A hackathon is a fun-spirited event where people come together and engage in collaborative product ideas! Participants have to wor﻿k quickly under the pressure of a time limit to create something the world hasn’t yet seen.
It is an informal and fun event environment encourages weird and wacky ideas. What The Hack that breaks barriers for hardware prototyping while being accessible to beginners.</p>,
      },
      {
        key: '2',
        label: "What is the expected submission?",
        children: <p>Within the two-day period of What The Hack, you are not expected to have a fully polished solution. A functioning prototype is expected, as well as an idea of how the complete product would function. Hardware, software or a combination of both are all accepted.</p>,
      },
      {
        key: '3',
        label: "Is this a physical or online hackathon?",
        children: <p>WTH 2024 is held in-person on the SUTD campus! All participants are required to be on-site for the set duration of the hackathon.</p>,
      },
      {
        key: '4',
        label: "Which platforms will be used for this hackathon?",
        children: <p>Announcements will be made on a Telegram channel. Keep a lookout for an email from us closer to the event for the link. Submissions will be uploaded to Devpost.</p>,
      },
      {
        key: '5',
        label: "Are we allowed to continue hacking after hacking spaces close on Day 1?",
        children: <p>No. All hacking spaces will be closed. Please come back again the next day!</p>,
      },
      {
        key: '6',
        label: "Will there be swag?",
        children: <p>Of course! Swag will be distributed during the hackathon.</p>,
      },
    ]
    const eligibility = [{
        key: '1',
        label: "Who is eligible to participate?",
        children: <p>All students aged 13 and above (as of 26th Aug 2023) registered at an educational institution in Singapore can participate. Students waiting for entry into university, including Full-Time National Servicemen (NSFs) are also eligible to participate.</p>,
      },
      {
        key: '2',
        label: "Do I need to sign up with a team?",
        children: <p>Yes. You must register together as a team of 3 to 5 members to participate.</p>,
      },
      {
        key: '3',
        label: "How would I know if my registration is confirmed?",
        children: <p>An email with more information will be sent to you after you submit the online signup form. If you do not receive the email within 3 days of signing up, do contact us.</p>,
      },
      {
        key: '4',
        label: "Do I need to have any background in Computer Science / Hacking?",
        children: <p>You do not need a background in either, although knowing some basic coding will help a lot. Just come with an open mind to learn and try new things!</p>,
      },
      {
        key: '5',
        label: "Do all members of the team need to be from the same school?",
        children: <p>No, teams can be made up of members from different institutions.</p>,
      },
      {
        key: '6',
        label: "Do all members of the team have to be present during the event?",
        children: <p>Teams must have at least 3 team members present during registration and during judging rounds</p>,
      }]

      const registration = [{
        key: '1',
        label: "When is the registration deadline?",
        children: <p>8 November 2024 at 2359 hours!</p>,
      },
      {
        key: '2',
        label: "Is this hackathon free?",
        children: <p>Yes, this hackathon is completely free. Join us and have fun, you never know what your ideas might turn into!</p>,
      }]

      const resources = [{
        key: '1',
        label: "What resources will I get when I join What The Hack?",
        children: <p>All teams will be receiving a Hackpack each, as well as access to a Makerspace, which has various tools that help you to make your ideas into reality.</p>,
      },
      {
        key: '2',
        label: "How do I access the Makerspace?",
        children: <p>Participants who wish to access the Makerspace will be required to sign an indemnity form at the SUTD's Fabrication Lab before access is granted.</p>,
      },
      {
        key: '3',
        label: "What is inside the Hackpack?",
        children: <p>Each hackpack contains an ESP32-S3, breadboard, RFID module, 16x2 Character LCD, IR sensor, ultrasonic sensor, and more! If you need more, we have LED strips, camera modules, acrylic, and plywood provided on an as-needed basis.</p>,
      },
      {
        key: '4',
        label: "What equipment will be availabe in the Makerspace?",
        children: <p>We have 3D printers, laser cutters, bench drills, scroll saws and basic and tools at your disposal!</p>,
      },
      {
        key: '5',
        label: "How do I access the Makerspace?",
        children: <p>In addition to the Hacking Spaces that you will be provided to work on your ideas, you will have access to a Makerspace, which is provided by SUTD’s fabrication Lab. Learn how to use a 3D printer and Laser Cutters, as well as woodworking equipment such as belt sanders, bandsaws, and drill presses. There will also be an electronics lab available, with access to solder and electronic components. Our student helpers will be glad to assist you if you are not familiar with the equipment.</p>,
      }]

      const others = [{
        key: '1',
        label: "Can I build my prototype beforehand?",
        children: <p>No, you may only begin working (coding, building, analysing) on your prototype after we announce the start of the hacking period.</p>,
      },
      {
        key: '2',
        label: "Do we get to pitch our idea?",
        children: <p>Yes! There will be an exhibition segment where you get to pitch your prototypes to the judges and others!</p>,
      },
      {
        key: '3',
        label: "How do we handle Intellectual Property (IP)?",
        children: <p>Apologies, but we currently do not have provisions for this.</p>,
      },
      {
        key: '4',
        label: "Will I get a certificate of partipation for taking part?",
        children: <p>Apologies, but we currently do not have provisions for this.</p>,
        cardBg: '#F5D143'
      },
      {
        key: '5',
        label: "Do I have to use a particular coding language?",
        children: <p>You are free to use any programming language you desire or need to use based on your prototype. There are no restrictions or requirements as to using specific programming languages.</p>,
      },
      {
        key: '6',
        label: "My question can't be found in the FAQs!",
        children: <p>For any further queries or clarifications, feel free to drop us a message via the contact form below, or email us at sutdwth@studentgov.sutd.edu.sg</p>,
      },
      {
        key: '7',
        label: "Will everyone who registers be able to participate in the hackathon?",
        children: <p>Due to limited capacity, not all registrants will be guaranteed participation. A shortlisting process will be conducted, and selected participants will be notified via email. Please note that selection is not on a first-come, first-served basis.</p>,
      }]

      const onChange = (key) => {
        console.log(key);
      };

      const items = [
        {
          key: '1',
          label: 'General',
          children: <ConfigProvider theme={{
            components: {
                Collapse:{
                headerBg:"#F5D143",
                contentBg:"#F5D143",
                controlInteractiveSize:10,
                colorBorder:"0",
                }
            }
        }}>
            <Collapse className="collapse" items={general} defaultActiveKey={[]} />
        </ConfigProvider>
        },

        {
          key: '2',
          label: 'Eligibility',
          children: <ConfigProvider theme={{
                    components: {
                        Collapse:{
                        headerBg:"#F26241",
                        contentBg:"#F26241",
                        colorBorder:"0"
                        }
                    }
                }}><Collapse className="collapse" items={eligibility} defaultActiveKey={[]} />
                        </ConfigProvider>,
        },

        {
          key: '3',
          label: 'Registration',
          children: <ConfigProvider theme={{
                    components: {
                        Collapse:{
                        headerBg:"#7C85D7",
                        contentBg:"#7C85D7",
                        colorBorder:"0"
                        }
                    }
                }}><Collapse className="collapse" items={registration} defaultActiveKey={[]} />
                        </ConfigProvider>,
        },

        {
          key: '4',
          label: 'Resources',
          children: <ConfigProvider theme={{
                    components: {
                        Collapse:{
                        headerBg:"#9FD75C",
                        contentBg:"#9FD75C",
                        colorBorder:"0"
                        }
                    }
                }}><Collapse className="collapse" items={resources} defaultActiveKey={[]} />
                </ConfigProvider>,
        },

        {
          key: '5',
          label: 'Others',
          children: <ConfigProvider theme={{
            components: {
                Collapse:{
                headerBg:"#3DE2DD",
                contentBg:"#3DE2DD",
                colorBorder:"0"
                }
            }
        }}><Collapse className="collapse" items={others} defaultActiveKey={[]} />
                </ConfigProvider>,
        }
      ];

      const form = useRef();
      const [openModal, setOpenModal] = useState(false);
      const [error, setError] = useState(false);
      const [errorMsg, setErrorMsg] = useState("");

    const sendEmail = (e) => {
      e.preventDefault();
      
      console.log(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current,process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current,process.env.REACT_APP_EMAILJS_PUBLIC_KEY) 
      .then((result) => {
          console.log('SUCCESS!');
            console.log(result.text);
            setErrorMsg("");
            setError(false);
            setOpenModal(true);

        }, (error) => {
          console.log('FAIL!');
            console.log(error.text,errorMsg);
            setError(true);
            if (errorMsg == ""){
              setErrorMsg("Sorry, service lost, please email us at sutdwth@studentgov.sutd.edu.sg");
            }
            else{
              setErrorMsg(error.text);
            }
            setOpenModal(true);
        });

        e.target.reset()
        setErrorMsg("");
    };

    return ( 
        <div className="faq">
            <p className="intro"><strong>Frequently Asked Questions</strong></p>
            <div className="question">
            <ConfigProvider
                theme={{
                components: {
                  Tabs: {
                    fontSize:18,
                    controlInteractiveSize:10,
                    },
                },
                }}
                
            ><Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </ConfigProvider>
            </div>

            <p className='large'>Contact Us</p>
            <div className='contact'>
                  <form className='form' ref={form} onSubmit={(e) => sendEmail(e)}>
                    <div className="input">
                        <label>Your Name*</label>
                        <input type="text" placeholder="To know you!" name="user_name" required/>
                    </div>
                    <div className="input">
                        <label>Your Email*</label>
                        <input type="email" placeholder="To contact you!" name="user_email" required/>
                    </div>
                    <div className="input">
                        <label>What would you like to talk to us about?*</label>
                        <textarea rows="4" required name="message" type="text" placeholder="We are always of service to you" />
                    </div>
                    <button className='submit' type="submit">Send</button>
                </form>
        
                    <ContactModal {...{openModal, setOpenModal, error, errorMsg}}/>
            </div>
            <Footer/>
        </div>
     );
}
 
export default Faq;
