import Footer from "./Footer";

const Prizes = () => {
  return (
    <div className="prizes">
      <p className="intro">
        <strong>Cash Prizes</strong> can be won by teams for one judging
        category while the wildcard prizes can be snatched by anyone! Join us
        and unleash your creativity as you tackle real word problems while
        having fun!
      </p>

      <div className="top">
        <div className="prize1" style={{ borderRadius: "20px 0px 0px 0px" }}>
          <p className="space">
            <strong>Circular Economy & Sustainable Living</strong>
          </p>
          <p className="space">2 winners, $1000 each</p>
        </div>
        <div className="prize2" style={{ borderRadius: "0px 20px 0px 0px" }}>
          <p className="space">
            <strong>Healthcare & Accessibility</strong>
          </p>
          <p className="space">2 winners, $1000 each</p>
        </div>
        <div className="prize3" style={{ borderRadius: "0px 0px 0px 20px" }}>
          <p className="space">
            <strong> Everyday Automation</strong>
          </p>
          <p className="space">2 winners, $1000 each</p>
        </div>
        <div className="prize4" style={{ borderRadius: "0px 0px 20px 0px" }}>
          <p className="space">
            <strong>Reducing Inequalities</strong>
          </p>
          <p className="space">2 winners, $1000 each</p>
        </div>
      </div>

      <p>
        <strong>Wildcard Prizes</strong>
      </p>
      <div className="full">
        <div className="special s1">
          <p className="space">
            <strong>Most Fablab-ulous Hardware Hack</strong>
          </p>
          <p className="space">1 winner, Electric Screwdriver</p>
        </div>
        <div className="special s2">
          <p className="space">
            <strong>Most Beautiful Hack</strong>
          </p>
          <p className="space">1 winner, Drawing tablet</p>
        </div>
        <div className="special s3">
          <p className="space">
            <strong>Wittiest Hack</strong>
          </p>
          <p className="space">1 winner, JBL earpiece</p>
        </div>
        <div className="special s4">
          <p className="space">
            <strong>Most Wholesome Hack</strong>
          </p>
          <p className="space">1 winner, Sticker printer with refills</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Prizes;
