import hyundai from '../sponsor/hyundai.png';
import motor from '../sponsor/motor.png';
import tiktok from '../sponsor/tiktok.png';
import expressif from '../sponsor/expressif.png';
import accenture from '../sponsor/accenture.png';
import govtech from '../sponsor/govtech.png';
import htx from '../sponsor/htx.png';
import ncs from '../sponsor/ncs.png';
import dbs from '../sponsor/dbs.png';
import hy from '../sponsor/hy.jpg';
import sutd_istd from '../sponsor/sutd_ISTD.png';
import imda from '../sponsor/imda.png';

const Sponsors = () => {
    return ( 
        <div className='sponsor'>
            <p className="intro enlarge lesspadding"><strong>Sponsors</strong></p>
            <div className="type platinum">
                <p className="platinum large">Platinum Sponsors:</p>
                <div className="logo-container split2">
                    <div className="company">
                        <img className="border platinum" src={hyundai} alt="company" />
                        <p className='spaceless'><strong>Hyundai Cradle</strong></p>
                    </div>
                    <div className="company">
                        <img className="border platinum" src={motor} alt="company" />
                        <p className='spaceless'><strong>Hyundai Motor Group</strong></p>
                    </div>
                </div>
            </div>

            <div className="type gold">
                <p className="gold large">Gold Sponsors:</p>
                <div className="logo-container split2">
                <div className="company">
                        <img className="border gold" src={htx} alt="company" />
                        <p className='spaceless'><strong>HTX</strong></p>
                    </div>
                    <div className="company">
                        <img className="border gold" src={tiktok} alt="company" />
                        <p className='spaceless'><strong>Tiktok</strong></p>
                    </div>
                </div>
            </div>

            <div className="type silver">
            <p className="silver large">Silver Sponsors:</p>
                <div className="logo-container">
                    <div className="company">
                        <img className="border silver" src={dbs} alt="company" />
                        <p className='spaceless'><strong>DBS</strong></p>
                    </div>
                </div>
            </div>

            <div className="type bronze">
            <p className="large bronze">Bronze Sponsors:</p>
                <div className="logo-container">
                    <div className='split3'>
                    <div className="company">
                            <img className="border bronze" src={accenture} alt="company" />
                            <p className='spaceless'><strong>Accenture</strong></p>
                        </div>
                        <div className="company">
                            <img className="border bronze" src={expressif} alt="company" />
                            <p className='spaceless'><strong>Espressif</strong></p>
                        </div>
                        <div className="company">
                            <img className="border bronze" src={govtech} alt="company" />
                            <p className='spaceless'><strong>GovTech</strong></p>
                        </div>
                    </div>
                    <div className='split2'>
                        <div className="company">
                            <img className="border bronze" src={imda} alt="company" />
                            <p className='spaceless'><strong>IMDA</strong></p>
                        </div>
                        <div className="company">
                            <img className="border bronze" src={ncs} alt="company" />
                            <p className='spaceless'><strong>NCS</strong></p>
                        </div>
                    </div>   
                </div>
            </div>
            <div className="type bronze">
                <p className="large bronze"><strong>Bronze Donor: </strong></p>
                    <div className="company">
                        <img className="border bronze" src={hy} alt="company" />
                        <p className='spaceless'><strong>Hsin Yi Pte Ltd</strong></p>
                    </div>
            </div>

            <p className="large support"><strong>Supported by </strong></p>
            <img className="other" src={sutd_istd} alt="company" />

            
        </div>
     );
}
 
export default Sponsors;