const WinnerTemplate1 = ({
    image,
    prize,
    solution,
    prototype
}) => {
    return ( 
        <div className="winner1">
            <div className="divder">
                <p className="prize nomargin">{prize}</p>
                <p className="solution nomargin">{solution}</p>
                <a href={prototype} target="_blank"><button className="prototype nomargin">Click Here to view the prototype!</button></a>
            </div>
        <img className="pic" src={image} alt="image" />              
    </div>
     );
}
 
export default WinnerTemplate1;
