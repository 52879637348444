import { createContext, useState } from "react";
import foodio from "../winners/foodio.jpg"
import emosense from "../winners/emosense.png"
import project from "../winners/projectPulsewatch.jpg"
import garupay from "../winners/garupay.jpg"

import car from "../winners/car.png"
import whack from "../winners/WHack.png"
import slay from "../winners/slay.png"

import arwand from "../winners/arwand.png"
import space from "../winners/space.png"
import value from "../winners/value.png"
import circuit from "../winners/circuit.png"

import write from "../winners/write.png"
import wind from "../winners/wind.png"
import eye from "../winners/eye.png"
import plasma from "../winners/plasma.png"
const TodoContext2023 = createContext({
  details2023: [{
    image: foodio,
    prize:"Circular Economy & Sustainable Living",
    solution:"The inventory management system monitors sales data and stock levels, to forecast demand and recommend optimal restocking quantities. Customers can place orders via a digital interface which roviding a better understanding of the actual food quantities.",
    prototype:"https://devpost.com/software/foodio-67c350"
  },
  {
    image: emosense,
    prize:"Inclusivity & Accessibility Tech",
    solution:"EmoSense is an innovative app designed to help individuals with emotional challenges understand emotions better. It interprets spoken words and offers personalized support, enabling users to connect more effectively with those around them.",
    prototype:"https://devpost.com/software/emosense"
  },
  {
    image: project,
    prize:"AI-enabled Internet-of-Things",
    solution:"The device monitors heart health by detecting irregular pulses and rates, using a pulse sensor and LCD screen. It measures every 30 seconds, providing continuous monitoring for heart attacks and other coronary heart diseases.",
    prototype:"https://devpost.com/software/project-pulsewatch-sraz5b"
  },
  {
    image: garupay,
    prize:"Financial Technologies",
    solution:"GaruPay simplifies bill splitting on Telegram with a user-friendly bot that automatically calculates amounts owed. It eliminates manual tracking, offers flexible payment input, and integrates with PayPal for easy transfers.",
    prototype:"https://devpost.com/software/garupay"
  }]
})

const TodoContext2022 = createContext({
  details2022: [{
    image: car,
    prize:"Sustainable & Smart Cities",
    solution:"The solution is a user-friendly mobile app and IoT system that helps drivers locate available parking spots in crowded car parks without needing to interact while driving. Users simply launch the app to see real-time availability across all floors.",
    prototype:"https://devpost.com/software/car-tell"
  },
  {
    image: whack,
    prize:"Healthcare & Accessibility Tech",
    solution:"The system uses magazines for different solid medicines, managed by a pharmacist. Patients take them home and insert them into a dispenser that syncs with their schedule. They then load a container, swipe their RFID card, and receive the correct dose.",
    prototype:"https://devpost.com/software/emosense"
  },
  {
    image: slay,
    prize:"Social Activities & Games",
    solution:"The app simplifies discovering fun activities to enjoy with friends, making it easy to reconnect with old friends and foster new connections. It promotes social interaction and helps strengthen bonds within existing and budding friendships.",
    prototype:"https://devpost.com/software/project-pulsewatch-sraz5b"
  }]
})

const TodoContext2018 = createContext({
  details2018: [{
    image: arwand,
    prize:"Art and Design + Best Overall hack",
    solution:"Augmented Reality (AR) currently faces limitations in content creation due to its restricted interaction capabilities. In contrast, Virtual Reality (VR) offers innovative avenues for content creation and prototyping. Our goal is to enhance AR by unlocking new interactive possibilities, enabling richer, more engaging experiences and creative opportunities.",
    prototype:"https://devpost.com/software/ar-wand"
  },
  {
    image: space,
    prize:"Education",
    solution:"We developed this budgeting app specifically for college students, addressing their tendency to overspend on snacks despite limited budgets. It encourages smart financial management and healthier choices using open data, aligning with the principles of a smart nation.",
    prototype:"https://devpost.com/software/whatthespace"
  },
  {
    image: value,
    prize:"Smart Cities",
    solution:"Inspired by the tendency of college students to overspend on snacks despite tight budgets, we created this budgeting application. It promotes smart financial management and healthy choices through open data, reflecting the hallmarks of a smart nation.",
    prototype:"https://devpost.com/software/value-nxa5b4"
  },
  {
    image: circuit,
    prize:"Best VR Hack",
    solution:"We set out to design a fun, open-ended game that teaches players fundamental physics concepts. Our focus on circuits stemmed from our enjoyment of the subject in school, and they seamlessly integrate into the turret defense theme, enhancing gameplay and learning.",
    prototype:"https://devpost.com/software/circuit-defense"
  }]
})

const TodoContext2017 = createContext({
  details2017: [{
    image: write,
    prize:"Education",
    solution:"This app helps teachers read messy handwriting when grading assignments. By simply taking a photo of the handwritten work, the app converts it into clear text, saving time and reducing frustration. This allows teachers to focus more on providing valuable feedback to their students, enhancing the grading experience.",
    prototype:"https://devpost.com/software/handwritingapp"
  },
  {
    image: wind,
    prize:"Environment + Best Hardware",
    solution:"Singapore has experienced haze from slash-and-burn practices in neighboring countries. Typically, people wear N95 masks in hazardous conditions, but the inconvenience and limited availability of masks led us to brainstorm alternative air filtration solutions.",
    prototype:"https://devpost.com/software/windshield"
  },
  {
    image: eye,
    prize:"Security + Best Software",
    solution:"Many individuals with disabilities often feel trapped and unable to fully live their lives. Our project, Eye VR, aims to help them thrive by enhancing their vision. This VR headset allows users to control a virtual wheelchair, unlock devices, and take exams using just their eyes, with multiple applications designed for versatility and ease of use.",
    prototype:"https://devpost.com/software/eye-gesture-control-application"
  },
  {
    image: plasma,
    prize:"Space",
    solution:"Space rockets require thrusters that utilize cheaper and lighter fuel to improve efficiency and reduce costs. By developing more accessible fuel options, we can enhance rocket performance, decrease launch expenses, and promote more sustainable space exploration.",
    prototype:"https://devpost.com/software/plasma-ion-space-thrusters"
  }]
})

function TodoProvider2023({childern}) {
    const [details2023] = useState([])

    const value = {details2023}

    return(
        <TodoContext2023.Provider value={value}>
            {childern}
        </TodoContext2023.Provider>
    )
}

function TodoProvider2022({childern}) {
  const [details2022] = useState([])

  const value = {details2022}

  return(
      <TodoContext2022.Provider value={value}>
          {childern}
      </TodoContext2022.Provider>
  )
}

function TodoProvider2018({childern}) {
  const [details2018] = useState([])

  const value = {details2018}

  return(
      <TodoContext2018.Provider value={value}>
          {childern}
      </TodoContext2018.Provider>
  )
}

function TodoProvider2017({childern}) {
  const [details2017] = useState([])

  const value = {details2017}

  return(
      <TodoContext2017.Provider value={value}>
          {childern}
      </TodoContext2017.Provider>
  )
}

export {TodoContext2023, TodoContext2022, TodoContext2018, TodoContext2017, TodoProvider2023, TodoProvider2022, TodoProvider2018, TodoProvider2017}